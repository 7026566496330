<template>
  <div v-if="version" class="version-chip">v{{ version }}</div>
</template>

<script lang="ts" setup>
import createHttpClient from '@/api/httpClient'
import { ref, onMounted } from 'vue'

interface IVersion {
  id: number // 1
  number: string // "1.1.5"
  createdAt: string // "2024-08-09T13:31:00+00:00"
  changelog: string // "<div>TEST</div>"
}

const version = ref('')
const httpClient = createHttpClient()
onMounted(async () => {
  const response = await httpClient.get<Array<IVersion>>('/version/list')
  version.value = response.data[0].number // first item is last version
})
</script>

<style scoped>
.version-chip {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1px 5px;
  font-weight: bold;
  font-size: 9px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: #333a;
  color: #fff;
  border-top-left-radius: 5px;
  z-index: 999;
}
</style>
